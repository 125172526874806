import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface IProp {
  value: any;
  setValue: any;
}

const ReleaseSelectProduct = ({ value, setValue }: IProp) => {
  const handleChange = (event: any) => setValue(event.target.value);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="select-product-input">Product</InputLabel>
        <Select
          labelId="select-product-label"
          id="select-product-select"
          value={value}
          label="product"
          onChange={handleChange}
        >
          <MenuItem value={"PurpleRain"}>Purple Rain</MenuItem>
          <MenuItem value={"QredoGlass"}>Qredo Glass</MenuItem>
          <MenuItem value={"Signing Agent"}>Signing Agent</MenuItem>
          <MenuItem value={"Explorer"}>Explorer</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ReleaseSelectProduct;
