import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

interface IProps {
  nextRelease: any;
  setNextRelease: any;
}

const ReleaseDeployJSON = ({ nextRelease, setNextRelease }: IProps) => {
  return (
    <>
      <JSONInput
        placeholder={nextRelease}
        theme="light_mitsuketa_tribute"
        locale={locale}
        height="550px"
        width="100%"
        onBlur={(x: any) => setNextRelease(x.jsObject)}
      />
    </>
  );
};

export default ReleaseDeployJSON;
