import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReleaseNavigation from "./components/release-navigation";

export default function ReleaseSearch() {
  const [productId, setProductId] = useState<string>();
  const [releaseList, setReleaseList] = useState<string[]>();
  const navigate = useNavigate();

  const printReleases = () => {
    return (
      <>
        {releaseList?.map((x: any) => {
          return (
            <Card variant="outlined">
              <CardContent>
                <Grid2 container spacing={2}>
                  <Grid2 xs={10}>
                    <h3>{x.release_name}</h3>
                  </Grid2>
                  <Grid2 xs={2}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        navigate(`/release-management/release/${x.release_id}`)
                      }
                    >
                      Edit
                    </Button>
                  </Grid2>
                </Grid2>
                <p>{x.release_date}</p>
                {parse(x.release_note)}
              </CardContent>
            </Card>
          );
        })}
      </>
    );
  };

  const updateProductId = (event: any) => {
    setProductId(event.target.value);
  };

  useEffect(() => {
    if (productId) {
      const getReleases = async () =>
        await axios
          .get(`${process.env.REACT_APP_QA_API}/release/product`, {
            params: {
              product_id: productId,
            },
          })
          .then((res) => {
            const data = res.data;
            setReleaseList(data);
          });
      getReleases();
    }
  }, [productId]);

  return (
    <>
      <ReleaseNavigation title="Search & Update Releases" />
      <Container maxWidth="sm">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <br />
              <br />
              <InputLabel id="select-environment-input">Product</InputLabel>
              <Select
                labelId="select-environment-label"
                id="select-environment-select"
                value={productId}
                label="Job Name"
                onChange={updateProductId}
              >
                <MenuItem value={"PurpleRain"}>Purple Rain</MenuItem>
                <MenuItem value={"SigningApp"}>Signing App</MenuItem>
                <MenuItem value={"GlassDoor"}>Glass Door</MenuItem>
                <MenuItem value={"FiftyShades"}>Fifty Shades</MenuItem>
              </Select>
            </FormControl>
            {printReleases()}
          </Stack>
        </Box>
      </Container>
    </>
  );
}
