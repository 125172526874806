import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

interface IProps {
  title: string;
}

const ReleaseNavigation = ({ title }: IProps) => {
  const navigate = useNavigate();

  return (
    <>
      <nav>
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroup variant="text" aria-label="text button group">
            <Button onClick={() => navigate("/release-management/release")}>
              New
            </Button>
            <Button
              onClick={() => navigate("/release-management/release/search")}
            >
              Update
            </Button>
            <Button
              onClick={() => navigate("/release-management/package/search")}
            >
              Search
            </Button>

            <Button onClick={() => navigate("/release-management/deploy")}>
              Deploy
            </Button>
          </ButtonGroup>
        </Container>
      </nav>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>{title}</h2>
      </Container>
    </>
  );
};

export default ReleaseNavigation;
