import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import ReleaseDeploySelect from "./release-deploy-select";
import ReleaseDeploySelectPackage from "./release-deploy-select-package";

const customStyles = {
  content: {
    width: "80%",
    height: "60%",
    top: "20%",
    left: "10%",
  },
};
interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: any;
  deployment: any;
}

export default function ReleaseDeployModal({
  modalIsOpen,
  setModalIsOpen,
  deployment,
}: IProps) {
  const [nextRelease, setNextRelease] = useState<any>();

  function closeModal() {
    setModalIsOpen(false);
  }

  useEffect(
    () =>
      setNextRelease(
        deployment.map((r: any) => {
          return {
            repo_id: r.repo_id,
            repo_name: r.repo_name,
            repo_group: r.repo_group,
            tag_name: r.tag_name,
          };
        })
      ),
    [deployment]
  );

  const [releaseId, setReleaseId] = useState<string>();
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Container maxWidth="sm">
        <ReleaseDeploySelect
          releaseId={releaseId}
          setReleaseId={setReleaseId}
        />
        <br />
        {releaseId ? (
          <>
            <ReleaseDeploySelectPackage
              releaseId={releaseId}
              nextRelease={nextRelease}
              setNextRelease={setNextRelease}
            />
            {/* <ReleaseDeployStartButton
              releaseId={releaseId}
              deployment={deployment}
            /> */}
          </>
        ) : (
          <></>
        )}
      </Container>
    </Modal>
  );
}
