import { msalInstance } from "../index";

export async function GetToken(): Promise<string> {
  const currentAccount = msalInstance.getActiveAccount();
  const accessTokenRequest: any = {
    scopes: [`api://${process.env.REACT_APP_APP_CLIENT_ID}/user_impersonation`],
    account: currentAccount,
  };
  if (currentAccount) {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );
    console.log(`Bearer ${accessTokenResponse.accessToken}`);
    return `Bearer ${accessTokenResponse.accessToken}`;
  }
  return "";
}
