import debounce from "lodash/debounce";
import { Component } from "react";
import RichTextEditor from "react-rte";

export default class RichTextEditorTextBox extends Component {
  componentDidMount() {
    if (this.props.value !== undefined)
      this.setState({
        value: RichTextEditor.createValueFromString(
          this.props.value || "",
          "html"
        ),
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: RichTextEditor.createValueFromString(
          nextProps.value || "",
          "html"
        ),
      });
    }
  }

  state = {
    value: RichTextEditor.createEmptyValue(),
  };

  onChange = (value) => {
    this.setState({ value });
  };

  saveAsHtml = () => {
    this.props.setValue(this.props.id, this.state.value.toString("html"));
  };

  saveText = debounce((e) => {
    this.props.setValue(this.props.id, e);
  }, 5000);

  render() {
    return (
      <RichTextEditor
        id={this.props.id}
        value={this.state.value}
        onChange={this.onChange}
        onBlur={this.saveAsHtml}
      />
    );
  }
}
