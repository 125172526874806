import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import ReleaseSelectReleaseName from "./release-select-releaseName";

interface IProps {
  // job: string | undefined;
  // setJob: any;
  releaseId: string | undefined;
  setReleaseId: any;
}

export default function ReleaseDeploySelect({
  // job,
  // setJob,
  releaseId,
  setReleaseId,
}: IProps) {
  return (
    <Container maxWidth="sm">
      <FormControl fullWidth>
        <br />
        <ReleaseSelectReleaseName value={releaseId} setValue={setReleaseId} />
        {/* {releaseId ? <ReleaseSelectJob job={job} setJob={setJob} /> : <></>} */}
      </FormControl>
    </Container>
  );
}
