import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReleaseNavigation from "./components/release-navigation";
import ReleaseTable from "./components/release-table";

export default function ReleasePackage() {
  const [deploymentResponse, setDeploymentResponse] = useState<any>([]);

  const location = useLocation();


  useEffect(() => {
    const urlSplit = location.pathname.split("/");
    if (urlSplit.length > 3) {
      const fetchLatestRelease = async () => {
        await axios
          .get(`${process.env.REACT_APP_QA_API}/deploy/status`, {
            params: {
              release_package_id: urlSplit.slice(-1)[0],
            },
          })
          .then((res) => {
            const data = res.data;
            setDeploymentResponse(data);
          });
      };
      fetchLatestRelease();
    }
  }, []);

  return (
    <>
      <ReleaseNavigation title="Release Package" />
      <br />
      <ReleaseTable deployment={deploymentResponse} />
    </>
  );
}
