import axios from "axios";
import { useEffect, useState } from "react";
import ReleaseNavigation from "./components/release-navigation";
import ReleaseTable from "./components/release-table";

const ReleasePage = () => {
  const [deployment, setDeployment] = useState<any[]>([]);

  useEffect(() => {
    const fetchLatestRelease = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_QA_API}/deploy/status/latest?job_name=QA deployment`
        )
        .then((res) => {
          const data = res.data;
          setDeployment(data);
        });
    };
    fetchLatestRelease();
  }, []);

  return (
    <>
      <ReleaseNavigation title={"PurpleRain Latest Deployment"} />
      <ReleaseTable deployment={deployment} />
    </>
  );
};

export default ReleasePage;
