import { InputLabel, MenuItem, Select } from "@mui/material";

interface IProp {
  job: any;
  setJob: any;
}

const ReleaseSelectJob = ({ job, setJob }: IProp) => {
  const handleChange = (event: any) => setJob(event.target.value);

  return (
    <>
      <InputLabel id="select-environment-input">Job Name</InputLabel>
      <Select
        labelId="select-environment-label"
        id="select-environment-select"
        value={job}
        label="Job Name"
        onChange={handleChange}
      >
        <MenuItem value={"Development deployment"}>
          Development deployment
        </MenuItem>
        <MenuItem value={"DEV02 deployment"}>DEV02 deployment</MenuItem>
        <MenuItem value={"QA deployment"}>QA deployment</MenuItem>
        <MenuItem value={"Staging deployment"}>Staging deployment</MenuItem>
        <MenuItem value={"Markets Dev deployment"}>
          Markets Dev deployment
        </MenuItem>
        <MenuItem value={"Security deployment"}>Security deployment</MenuItem>
        <MenuItem value={"Prod deployment"}>Production deployment</MenuItem>
      </Select>
    </>
  );
};

export default ReleaseSelectJob;
