import Iframe from "react-iframe";
const ReportsPage = () => {
  return (
    <Iframe
      url="https://report.development-qredo.com"
      position="absolute"
      width="100%"
      height="100%"
    />
  );
};

export default ReportsPage;
