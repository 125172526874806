import { FormControl } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { ReactNode, SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReleaseNavigation from "./components/release-navigation";
import ReleasePackageTable from "./components/release-package-table";
import ReleaseSelectJob from "./components/release-select-job";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReleasePackageSearch() {
  const [value, setValue] = useState(0);
  const [disable, setDisable] = useState<boolean>(false);
  const [job, setJob] = useState<string>("");
  const [releaseId, setReleaseId] = useState();
  const [lastReleaseId, setLastReleaseId] = useState();
  const [releasePackageId, setReleasePackageId] = useState<string>("");
  const [deployment, setDeployment] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeReleasePackageId = (event: any) => {
    setReleasePackageId(event.target.value);
  };
  const handleChangeReleaseId = (event: any) => {
    setReleaseId(event.target.value);
  };
  const handleChangeLastReleaseId = (event: any) => {
    setLastReleaseId(event.target.value);
  };

  const searchByReleaseName = async () => {
    try {
      setDisable(true);
      setDeployment([]);
      await axios
        .get(`${process.env.REACT_APP_QA_API}/deploy/status`, {
          params: {
            job_name: job,
          },
        })
        .then((res) => {
          const response = res.data;
          setDeployment(response);
          setDisable(false);
        });
    } catch (error) {
      setDisable(false);
    }
  };

  const searchById = async () => {
    try {
      setDisable(true);
      setDeployment([]);
      await axios
        .get(`${process.env.REACT_APP_QA_API}/deploy/status`, {
          params: {
            release_package_id: releasePackageId,
          },
        })
        .then((res) => {
          const response = res.data;
          setDeployment(response);
          setDisable(false);
        });
    } catch (error) {
      setDisable(false);
    }
  };

  return (
    <>
      <ReleaseNavigation title="Search for Release Package" />
      <Container maxWidth="sm">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Environment" {...a11yProps(0)} />
              <Tab label="ID" {...a11yProps(1)} />
              <Tab label="Release Note" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Container maxWidth="sm">
              <FormControl fullWidth>
                <ReleaseSelectJob job={job} setJob={setJob} />
                <br />
                <Button
                  variant="contained"
                  disabled={disable}
                  onClick={() => searchByReleaseName()}
                >
                  Search
                </Button>
              </FormControl>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Container maxWidth="sm">
              <FormControl fullWidth>
                <TextField
                  id="standard-basic"
                  label="Release Id"
                  variant="standard"
                  onChange={handleChangeReleasePackageId}
                />
                <br />
                <Button
                  variant="contained"
                  disabled={disable}
                  onClick={() => searchById()}
                >
                  Search
                </Button>
              </FormControl>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Container maxWidth="sm">
              <FormControl fullWidth>
                <TextField
                  id="last-release"
                  label="Last Release Id"
                  variant="standard"
                  onChange={handleChangeLastReleaseId}
                />
                <TextField
                  id="release"
                  label="Release Id"
                  variant="standard"
                  onChange={handleChangeReleaseId}
                />
                <br />
                <Button
                  variant="contained"
                  // disabled={disable}
                  onClick={() =>
                    navigate(
                      `/release-management/package/notes/${lastReleaseId}/${releaseId}`
                    )
                  }
                >
                  Search
                </Button>
              </FormControl>
            </Container>
          </TabPanel>
        </Box>
      </Container>
      <hr />
      <ReleasePackageTable releasePackages={deployment} />
    </>
  );
}
