import { AuthenticatedTemplate } from "@azure/msal-react";
import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { GetToken } from "../../../utils/auth-token";
import ReleaseDeployModal from "./release-deploy-modal";
import ReleaseNote from "./release-note";

interface IProps {
  deployment: any;
}

const ReleaseTable = ({ deployment }: IProps) => {
  const [disable, setDisable] = useState<boolean>(false);
  const [nextRelease, setNextRelease] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (deployment.length > 0) {
      const unsortedRelease = deployment.filter(
        (x: any) => x.release_id === deployment[0].release_id
      );
      const sortedRelease = orderBy(unsortedRelease, "job_created_at", "desc");
      setNextRelease(sortedRelease);
    }
  }, [deployment]);

  const startDeploymentFix = async () => {
    setDisable(true);
    setNextRelease([]);
    try {
      const bearer = await GetToken();
      await axios
        .post(`${process.env.REACT_APP_QA_API}/deploy/fix`, nextRelease, {
          headers: {
            Authorization: bearer,
          },
        })
        .then((res: any) => {
          const response = res.data;
          setNextRelease(response);
        });
    } catch (error) {}
    setDisable(false);
  };

  return nextRelease.length ? (
    <Container maxWidth="md">
      <ReleaseDeployModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        deployment={deployment}
      />
      <br />
      <ReleaseNote release={{ release_id: deployment[0]?.release_id }} />
      <br />
      <Box>
        <Grid2 container spacing={2}>
          <Grid2 xs={10}>
            <Box>
              <Stack spacing={0}>
                <div>
                  Environment: <b>{nextRelease[0]?.job_name}</b>
                </div>
              </Stack>
            </Box>
          </Grid2>
          <Grid2 xs={2} display="flex" justifyContent="flex-end">
            <AuthenticatedTemplate>
              <Button
                variant="contained"
                onClick={() => startDeploymentFix()}
                disabled={disable}
              >
                Fix
              </Button>
              <Button
                variant="contained"
                onClick={() => setModalIsOpen(true)}
                disabled={disable}
              >
                Deploy
              </Button>
            </AuthenticatedTemplate>
          </Grid2>
        </Grid2>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Repository</TableCell>
                <TableCell align="right">Tag</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Job</TableCell>
                <TableCell align="right">Pipeline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nextRelease.map((repo: any) => (
                <TableRow
                  key={repo.repo_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      sx={{ textTransform: "capitalize", fontSize: "16px" }}
                      variant="h6"
                    >
                      {repo.repo_name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href={`https://gitlab.qredo.com/${repo.repo_group}/${repo.repo_name}/-/tags/${repo.tag_name}`}
                      underline="hover"
                      target="_blank"
                    >
                      {repo.tag_name}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    {new Date(repo.job_created_at).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{repo.job_status}</TableCell>
                  <TableCell align="right">
                    <Link
                      href={`https://gitlab.qredo.com/${repo.repo_group}/${repo.repo_name}/-/jobs/${repo.job_id}`}
                      underline="hover"
                      target="_blank"
                    >
                      {repo.job_id}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href={`https://gitlab.qredo.com/${repo.repo_group}/${repo.repo_name}/-/pipelines/${repo.pipeline_id}`}
                      underline="hover"
                      target="_blank"
                    >
                      {repo.pipeline_id}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <br />
    </Container>
  ) : (
    <></>
  );
};

export default ReleaseTable;
