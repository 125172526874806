import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import "./index.css";
import ReleasePage from "./pages/release";
import ReleaseDeploy from "./pages/release/release-deploy";
import ReleaseNew from "./pages/release/release-new";
import ReleasePackage from "./pages/release/release-package";
import ReleasePackageSearch from "./pages/release/release-package-search";
import ReleaseSearch from "./pages/release/release-search";
import ReportsPage from "./pages/reports";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
// import "bootstrap/dist/css/bootstrap.min.css";
import { msalConfig } from "./authConfig";

import { EventType } from "@azure/msal-browser";

import { MsalProvider } from "@azure/msal-react";
import ReleasePackageNotes from "./pages/release/release-package-notes";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./styles/index.css";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const activeAccount = msalInstance?.getActiveAccount() as any;
  if (activeAccount !== undefined)
    msalInstance.setActiveAccount(activeAccount[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    (event.payload as any).account
  ) {
    const account = (event.payload as any).account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    {/* <PageLayout> */}
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="release-management" element={<ReleasePage />} />
          <Route path="release-management/release*" element={<ReleaseNew />} />
          <Route path="release-management/deploy" element={<ReleaseDeploy />} />
          <Route
            path="release-management/deploy/*"
            element={<ReleasePackage />}
          />
          <Route
            path="release-management/release/package"
            element={<ReleasePackageSearch />}
          />
          <Route
            path="release-management/release/search"
            element={<ReleaseSearch />}
          />
          <Route
            path="release-management/package/search"
            element={<ReleasePackageSearch />}
          />
          <Route
            path="release-management/package/notes*"
            element={<ReleasePackageNotes />}
          />
          <Route path="reports" element={<ReportsPage />} />
        </Routes>
      </main>
    </Router>
    {/* </PageLayout> */}
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
