import { Container, Link } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";

interface IProps {
  releasePackages: any;
}

const ReleasePackageTable = ({ releasePackages }: IProps) => {
  const [deployments, setDeployments] = useState<any>([]);


  const getStatus = (releasePackageId: string) => {
    const deployment = releasePackages.filter(
      (x: any) => x.release_package_id === releasePackageId
    );
    return uniqBy(deployment, "job_status");
  };

  useEffect(() => {
    if (releasePackages.length > 0) {
      setDeployments([]);
      const pkg = uniqBy(releasePackages, "release_package_id");
      setDeployments(pkg);
    }
  }, [releasePackages]);

  return releasePackages.length ? (
    <Container maxWidth="md">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Release Package</TableCell>
              <TableCell align="right">Invoker</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deployments.map((repo: any) => (
              <TableRow
                key={repo.release_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link
                    href={`/release-management/deploy/${repo.release_package_id}`}
                    underline="hover"
                    target="_blank"
                  >
                    {repo.release_package_id}
                  </Link>
                </TableCell>
                <TableCell align="right">{repo.job_invoker}</TableCell>
                <TableCell align="right">
                  {new Date(
                    repo.release_package_created_at || repo.job_created_at
                  ).toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  <Stack spacing={0}>
                    {getStatus(repo.release_package_id).map((x: any) => (
                      <cite>{x.job_status}</cite>
                    ))}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  ) : (
    <></>
  );
};
export default ReleasePackageTable;
