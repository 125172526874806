import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditorTextBox from "../../components/rich-text-editor-text-box";
import ReleaseNavigation from "./components/release-navigation";
import ReleaseSelectProduct from "./components/release-select-product";

export default function ReleaseNew() {
  const [releaseDate, setReleaseDate] = useState<Dayjs | null | undefined>(
    dayjs()
  );
  const [releaseNote, setReleaseNote] = useState<string>();
  const [releaseName, setReleaseName] = useState<string>();
  const [productId, setProductId] = useState<string>();
  const [releaseId, setReleaseId] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>();

  const navigate = useNavigate();
  const location = useLocation();

  const formCondition = () =>
    productId === undefined ||
    releaseDate === undefined ||
    releaseName === undefined ||
    releaseNote === undefined;

  const updateReleaseDate = (newValue: Dayjs | null) => {
    setReleaseDate(newValue);
  };

  const updateReleaseNote = (name: string, value: string) =>
    setReleaseNote(value);

  const updateReleaseName = (event: any) => {
    setReleaseName(event.target.value);
  };

  const saveNewRelease = async () =>
    releaseId
      ? await axios
          .patch(`${process.env.REACT_APP_QA_API}/release`, {
            release_id: releaseId,
            product_id: productId,
            release_name: releaseName,
            release_date: releaseDate,
            release_note: releaseNote,
          })
          .then((res) => {
            const data = res.data;
            navigate(data.release_id);
          })
      : await axios
          .post(`${process.env.REACT_APP_QA_API}/release`, {
            product_id: productId,
            release_name: releaseName,
            release_date: releaseDate,
            release_note: releaseNote,
          })
          .then((res) => {
            const data = res.data;
            navigate(data.release_id);
          });

  useEffect(
    () => setDisabled(formCondition),
    [productId, releaseDate, releaseName, releaseNote]
  );

  useEffect(() => {
    const urlSplit = location.pathname.split("/");
    if (urlSplit.length > 3) {
      const id = urlSplit.slice(-1)[0];
      setReleaseId(id);
      const fetchLatestRelease = async () => {
        await axios
          .get(`${process.env.REACT_APP_QA_API}/release`, {
            params: {
              release_id: id,
            },
          })
          .then((res) => {
            const data = res.data;
            setProductId(data?.product_id);
            setReleaseName(data?.release_name);
            setReleaseDate(data?.release_date);
            setReleaseNote(data?.release_note);
          });
      };
      fetchLatestRelease();
    }
  }, []);

  return (
    <>
      <ReleaseNavigation title="Add Planned Release" />
      <Container maxWidth="sm">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <ReleaseSelectProduct value={productId} setValue={setProductId} />
              <TextField
                id="standard-basic"
                label="Name"
                variant="standard"
                value={releaseName || " "}
                onChange={updateReleaseName}
              />
              <DateTimePicker
                label="Due Date"
                value={releaseDate}
                onChange={updateReleaseDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <RichTextEditorTextBox
                id="test"
                value={releaseNote}
                setValue={updateReleaseNote}
              />
              <Button
                variant="contained"
                disabled={disabled}
                onClick={saveNewRelease}
              >
                Save
              </Button>
            </Stack>
          </LocalizationProvider>
        </Box>
      </Container>
    </>
  );
}
