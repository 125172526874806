import Stack from "@mui/material/Stack";
import { useState } from "react";

interface IProps {
  nextRelease: any;
  setNextRelease: any;
}

const ReleaseDeployUI = ({ nextRelease, setNextRelease }: IProps) => {
  const [exclude, setExclude] = useState<Number[]>([]);

  const handleChange = (event: any) => {
    const val = parseInt(event.target.value);
    let nextExclude = exclude.filter((x: any) => x !== val);
    if (event.target.checked === false) {
      nextExclude = [...nextExclude, Number(val)];
    } else {
      nextExclude = [...nextExclude];
    }
    setExclude([...nextExclude]);
    setNextRelease(
      nextRelease.filter((x: any) => {
        return nextExclude.includes(Number(x.repo_id)) === false;
      })
    );
  };

  const printReleaseCandidates = () => {
    if (nextRelease.length > 0) {
      return (
        <Stack spacing={2}>
          <p>Select repo to deploy:</p>
          {nextRelease.map((repo: any) => (
            <label key={repo.repo_id}>
              <input
                value={repo.repo_id}
                type="checkbox"
                checked={!exclude.includes(repo.repo_id)}
                onChange={handleChange}
              />
              {`${repo?.repo_name} - ${repo?.tag_name}`}
            </label>
          ))}
        </Stack>
      );
    }
    return <></>;
  };

  return <>{printReleaseCandidates()}</>;
};

export default ReleaseDeployUI;
