import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import parse from "html-react-parser";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  release: any;
}

export default function ReleaseNote({ release }: IProps) {
  const [thisRelease, setThisRelease] = React.useState<any>();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (release.release_id) {
      const getReleases = async () =>
        await axios
          .get(`${process.env.REACT_APP_QA_API}/release`, {
            params: {
              release_id: release.release_id,
            },
          })
          .then((res) => {
            const data = res.data;
            setThisRelease(data);
          });
      getReleases();
    }
  }, [release]);

  return release && thisRelease ? (
    <Card variant="outlined">
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 xs={10}>
            <h3>{thisRelease?.release_name}</h3>
          </Grid2>
          <Grid2 xs={2}>
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `/release-management/release/${thisRelease?.release_id}`
                )
              }
            >
              Edit
            </Button>
          </Grid2>
        </Grid2>
        <p>{new Date(thisRelease?.release_date).toLocaleString()}</p>
        {parse(thisRelease?.release_note)}
      </CardContent>
    </Card>
  ) : (
    <></>
  );
}
