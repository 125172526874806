import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ReleasePackageNotes() {
  const [notes, setNotes] = useState<any[]>([]);

  const location = useLocation();

  useEffect(() => {
    const urlSplit = location.pathname.split("/");
    if (urlSplit.length > 4) {
      const lastReleaseId = urlSplit[4];
      const releaseId = urlSplit[5];
      const fetchNote = async () => {
        await axios
          .get(`${process.env.REACT_APP_QA_API}/notes`, {
            params: {
              last_release_id: lastReleaseId,
              release_id: releaseId,
            },
          })
          .then((res) => {
            const data = res.data;
            // debugger;
            setNotes(data);
          });
      };
      fetchNote();
    }
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="right">Author</TableCell>
              <TableCell align="right">Commit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {notes.map((commits: any) => {
                if (commits.length > 0) {
                  return commits.map((commit: any) => (
                    <TableRow
                      key={commit.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        {new Date(commit.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title={commit.message}>
                          <Box
                            sx={{
                              // bgcolor: "text.disabled",
                              // color: "background.paper",
                              // p: 2,
                            }}
                          >
                            {commit.title}
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">{commit.author_name}</TableCell>
                      <TableCell align="right">
                        <Link
                          href={commit.web_url}
                          underline="hover"
                          target="_blank"
                        >
                          <OpenInNewIcon />
                          {/* <Icon color="primary">add_circle</Icon> */}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ));
                }
              })}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
