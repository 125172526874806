import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ReleaseDeployJSON from "./release-deploy-json";
import ReleaseDeployStartButton from "./release-deploy-start-button";
import ReleaseDeployStartButtonGitlab from "./release-deploy-start-button-gitlab";
import ReleaseDeployUI from "./release-deploy-ui";

interface IProps {
  releaseId?: string;
  nextRelease?: any;
  setNextRelease?: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReleaseDeploySelectPackage({
  releaseId,
  nextRelease,
  setNextRelease,
}: IProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {nextRelease.length > 0 ? (
        <>
          <Container maxWidth="sm">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="UI" {...a11yProps(0)} />
                  <Tab label="JSON" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Container maxWidth="sm">
                  <ReleaseDeployUI
                    nextRelease={nextRelease}
                    setNextRelease={setNextRelease}
                  />
                </Container>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Container maxWidth="sm">
                  <ReleaseDeployJSON
                    nextRelease={nextRelease}
                    setNextRelease={setNextRelease}
                  />
                </Container>
              </TabPanel>
            </Box>
            <br />
            {/* {JSON.stringify(nextRelease)} */}
            <ReleaseDeployStartButtonGitlab
              releaseId={releaseId}
              deployment={nextRelease}
            />
            <ReleaseDeployStartButton
              releaseId={releaseId}
              deployment={nextRelease}
            />
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
