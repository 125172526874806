import { AuthenticatedTemplate } from "@azure/msal-react";
import { Button, FormControl } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { GetToken } from "../../../utils/auth-token";
import ReleaseSelectJob from "./release-select-job";

interface IProps {
  deployment: any;
  releaseId: string | undefined;
}

export default function ReleaseDeployStartButton({
  deployment,
  releaseId,
}: IProps) {
  const [disable, setDisable] = useState<boolean>(false);
  const [job, setJob] = useState<string>();

  const startDeployment = async () => {
    setDisable(true);
    try {
      const bearer = await GetToken();
      await axios
        .post(`${process.env.REACT_APP_QA_API}/gitops`, deployment, {
          params: {
            job_name: job,
            release_id: releaseId,
          },
          headers: {
            Authorization: bearer,
          },
        })
        .then((res) => {
          window.location.href = `${window.location.origin}/release-management/deploy/${res.data[0].release_package_id}`;
        });
    } catch (error) {}
    setDisable(false);
  };

  return (
    <AuthenticatedTemplate>
      <FormControl fullWidth>
        <ReleaseSelectJob job={job} setJob={setJob} />
        <br />
        <Button
          variant="contained"
          onClick={async () => await startDeployment()}
          disabled={disable}
        >
          Create GITOPS MR
        </Button>
        <br />
        <br />
      </FormControl>
    </AuthenticatedTemplate>
  );
}
