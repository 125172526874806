import Box from "@material-ui/core/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { useEffect, useState } from "react";
import { GetToken } from "../../../utils/auth-token";
import ReleaseSelectProduct from "./release-select-product";

interface IProp {
  value: any;
  setValue: any;
}

const ReleaseSelectReleaseName = ({ value, setValue }: IProp) => {
  const [productId, setProductId] = useState<string>();
  const [releaseList, setReleaseList] = useState<string[]>([]);

  const handleChange = (event: any) => setValue(event.target.value);

  useEffect(() => {
    if (productId) {
      const getReleases = async () => {
        const bearer = await GetToken();

        return await axios
          .get(`${process.env.REACT_APP_QA_API}/release/product`, {
            params: {
              product_id: productId,
            },
            headers: {
              Authorization: bearer,
            },
          })
          .then((res) => {
            const data = res.data;
            setReleaseList(data);
          });
      };
      getReleases();
    }
  }, [productId]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <ReleaseSelectProduct value={productId} setValue={setProductId} />
      <br />
      {productId ? (
        <FormControl fullWidth>
          <InputLabel id="select-release-input">Release</InputLabel>
          <Select
            labelId="select-release-label"
            id="select-release-select"
            value={value}
            label="release"
            onChange={handleChange}
          >
            {releaseList.map((x: any) => (
              <MenuItem value={x.release_id}>{x.release_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ReleaseSelectReleaseName;
