import { Button, Container } from "@material-ui/core";
import axios from "axios";
import { useState } from "react";
import ReleaseDeploySelect from "./components/release-deploy-select";
import ReleaseDeploySelectPackage from "./components/release-deploy-select-package";
import ReleaseNavigation from "./components/release-navigation";

export default function ReleaseDeploy() {
  const [releaseId, setReleaseId] = useState<string>();

  const [disable, setDisable] = useState<boolean>(false);
  const [nextRelease, setNextRelease] = useState<any>([]);
  const [force, setForce] = useState<boolean>(false);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const generateReleasePackage = async () => {
    setDisable(true);
    setNextRelease([]);
    await axios
      .get(`${process.env.REACT_APP_QA_API}/release/next`, {
        params: { force: force },
      })
      .then((res: any) => {
        const nextRelease = res.data;
        setNextRelease(nextRelease);
      });
    setDisable(false);
  };

  return (
    <>
      <ReleaseNavigation title="Create a Release Package" />
      <ReleaseDeploySelect releaseId={releaseId} setReleaseId={setReleaseId} />
      {releaseId ? (
        <>
          <Container maxWidth="sm">
            <p>Get latest tags for PurpleRain:</p>
            <Button
              variant="contained"
              onClick={() => generateReleasePackage()}
              disabled={disable}
            >
              Get Repos
            </Button>
            <label>
              <input
                type="checkbox"
                checked={force}
                onChange={(event: any) => setForce(event.target.checked)}
              />
              Force
            </label>
            <br />
          </Container>
          <ReleaseDeploySelectPackage
            releaseId={releaseId}
            nextRelease={nextRelease}
            setNextRelease={setNextRelease}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
